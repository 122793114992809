import "./style.css";
import SvgComponent from "../components/SvgComponent";
import Container from 'react-bootstrap/Container';
//Languages
import C from "../assets/Technologies/Language/C.png";
import Java from "../assets/Technologies/Language/Java.webp";
import Js from "../assets/Technologies/Language/Js.png";
import HTML from "../assets/Technologies/Language/html.png";
import CSS from "../assets/Technologies/Language/css.png";
import Python from "../assets/Technologies/Language/Python.png";
import typescript from "../assets/Technologies/Language/typescript.png";

//Framwork
import Angular from "../assets/Technologies/Framework/Angular.png";
import flutter from "../assets/Technologies/Framework/flutter.png";
import React from "../assets/Technologies/Framework/React.png";
import vue from "../assets/Technologies/Framework/vue.png";
import bootstrap from "../assets/Technologies/Framework/bootstrap.png";
import dj from "../assets/Technologies/Framework/dj.png";
import laravel from "../assets/Technologies/Framework/laravel.png";

//Database
import Postgre from "../assets/Technologies/Database/Postgre.png";
import mysql from "../assets/Technologies/Database/mysql.png";
import firebase from "../assets/Technologies/Database/firebase.png";
import mongo from "../assets/Technologies/Database/mongo.png";
import oracle from "../assets/Technologies/Database/oracle.png";
import pine from "../assets/Technologies/Database/pine.png";
import redis from "../assets/Technologies/Database/redis.jpg";


const TechnologiesSec = () => {
    return (
        <div className="main-service main-technologies">
            <div className="main-service-hd-1 main-tech-hd-1">
                <h1
                    className="main-procedure-hd main-technologies-hd"
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-duration="700"
                >
                    Technologies we work with
                </h1>
                <SvgComponent />
            </div>
            <Container className="service-container mt-5">
                <div className="technologies-1">
                    <div className="tech-hd">
                        <button className="hd-btn">
                            Languages
                        </button>
                    </div>
                    <div className="technology-flex">
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={C} alt="" className="images-tech" />
                                <p>C#</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Java} alt="" className="images-tech" />
                                <p>Java</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Js} alt="" className="images-tech" />
                                <p>Js</p>
                            </div>
                            <div className="tech transistion">
                                <img src={HTML} alt="" className="images-tech" />
                                <p>HTML5</p>
                            </div>
                            <div className="tech transistion">
                                <img src={CSS} alt="" className="images-tech" />
                                <p>CSS3</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Python} alt="" className="images-tech" />
                                <p>Python</p>
                            </div>
                            <div className="tech transistion">
                                <img src={typescript} alt="" className="images-tech" />
                                <p>type script</p>
                            </div>
                        </div>
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={C} alt="" className="images-tech" />
                                <p>C#</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Java} alt="" className="images-tech" />
                                <p>Java</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Js} alt="" className="images-tech" />
                                <p>Js</p>
                            </div>
                            <div className="tech transistion">
                                <img src={HTML} alt="" className="images-tech" />
                                <p>HTML5</p>
                            </div>
                            <div className="tech transistion">
                                <img src={CSS} alt="" className="images-tech" />
                                <p>CSS3</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Python} alt="" className="images-tech" />
                                <p>Python</p>
                            </div>
                            <div className="tech transistion">
                                <img src={typescript} alt="" className="images-tech" />
                                <p>type script</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="technologies-1">
                    <div className="tech-hd">
                        <button className="hd-btn">
                            Frameworks
                        </button>
                    </div>
                    <div className="technology-flex">
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={Angular} alt="" className="images-tech" />
                                <p>Angular</p>
                            </div>
                            <div className="tech transistion">
                                <img src={flutter} alt="" className="images-tech" />
                                <p>Flutter</p>
                            </div>
                            <div className="tech transistion">
                                <img src={bootstrap} alt="" className="images-tech" />
                                <p>
                                    Bootstrap
                                </p>
                            </div>
                            <div className="tech transistion">
                                <img src={dj} alt="" className="images-tech" />
                                <p>Django</p>
                            </div>
                            <div className="tech transistion">
                                <img src={React} alt="" className="images-tech" />
                                <p>React</p>
                            </div>
                            <div className="tech transistion">
                                <img src={vue} alt="" className="images-tech" />
                                <p>Vue</p>
                            </div>
                            <div className="tech transistion">
                                <img src={laravel} alt="" className="images-tech" />
                                <p>Laravel</p>
                            </div>
                        </div>
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={Angular} alt="" className="images-tech" />
                                <p>Angular</p>
                            </div>
                            <div className="tech transistion">
                                <img src={flutter} alt="" className="images-tech" />
                                <p>Flutter</p>
                            </div>
                            <div className="tech transistion">
                                <img src={bootstrap} alt="" className="images-tech" />
                                <p>
                                    Bootstrap
                                </p>
                            </div>
                            <div className="tech transistion">
                                <img src={dj} alt="" className="images-tech" />
                                <p>Django</p>
                            </div>
                            <div className="tech transistion">
                                <img src={React} alt="" className="images-tech" />
                                <p>React</p>
                            </div>
                            <div className="tech transistion">
                                <img src={vue} alt="" className="images-tech" />
                                <p>Vue</p>
                            </div>
                            <div className="tech transistion">
                                <img src={laravel} alt="" className="images-tech" />
                                <p>Laravel</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="technologies-1">
                    <div className="tech-hd">
                        <button className="hd-btn">
                            Database
                        </button>
                    </div>
                    <div className="technology-flex">
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={redis} alt="" className="images-tech" />
                                <p>Redis</p>
                            </div>
                            <div className="tech transistion">
                                <img src={oracle} alt="" className="images-tech" />
                                <p>Oracle</p>
                            </div>
                            <div className="tech transistion">
                                <img src={firebase} alt="" className="images-tech" />
                                <p>FireBase</p>
                            </div>
                            <div className="tech transistion">
                                <img src={mongo} alt="" className="images-tech" />
                                <p>Mongo</p>
                            </div>
                            <div className="tech transistion">
                                <img src={mysql} alt="" className="images-tech" />
                                <p>MySQL</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Postgre} alt="" className="images-tech" />
                                <p>Postgre</p>
                            </div>
                            <div className="tech transistion">
                                <img src={pine} alt="" className="images-tech" />
                                <p>Pine Kone</p>
                            </div>
                        </div>
                        <div className="technology">
                            <div className="tech transistion">
                                <img src={redis} alt="" className="images-tech" />
                                <p>Redis</p>
                            </div>
                            <div className="tech transistion">
                                <img src={oracle} alt="" className="images-tech" />
                                <p>Oracle</p>
                            </div>
                            <div className="tech transistion">
                                <img src={firebase} alt="" className="images-tech" />
                                <p>FireBase</p>
                            </div>
                            <div className="tech transistion">
                                <img src={mongo} alt="" className="images-tech" />
                                <p>Mongo</p>
                            </div>
                            <div className="tech transistion">
                                <img src={mysql} alt="" className="images-tech" />
                                <p>MySQL</p>
                            </div>
                            <div className="tech transistion">
                                <img src={Postgre} alt="" className="images-tech" />
                                <p>Postgre</p>
                            </div>
                            <div className="tech transistion">
                                <img src={pine} alt="" className="images-tech" />
                                <p>Pine Kone</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default TechnologiesSec;