import "./style.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { FaPhoneAlt, FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdDoubleArrow } from "react-icons/md";
import FooterLogo from "../../assets/footer-logo.png";
import FooterVideo from '../../assets/Footer_Animation.mp4';

const pages = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/AboutUs",
  },
  {
    name: "Careers",
    link: "/Career",
  },
  {
    name: "Services",
    link: "/Services",
  }
]


export default function Footer() {
  const mapLink = 'https://maps.app.goo.gl/bnNUoZ41h8wA4bLH7'
  const map = 'North Nazimabad, Karachi, Sindh'
  const phoneLink = '+923183253219';
  const phone = '+92 318 3253219';
  const facebookLink = 'https://www.facebook.com/profile.php?id=61558502883884&mibextid=LQQJ4d';
  const instagramLink = 'https://www.instagram.com/ayetec.co?igsh=NGV5bTNlNWk5d2F5&utm_source=qr';
  const twitterLink = '';
  const linkedInLink = '';

  return (
    <div className="main-footer me-auto" >
      <div className="upper-footer">
        <div className="background-video-div">
          <video src={FooterVideo} muted loop autoPlay type="video/mp4"></video>
        </div>
        <Container className="footer-container">
          <div className="sub-footer">
            <div className="logo-div footer-logo-div">
              <img src={FooterLogo} alt="Footer-Ayetec" className="footer-logo" />
            </div>
            <div className="text-div">
              <div className="d-flex">
                <p className="icon-div-footer">
                  <FaPhoneAlt className="location-icon transistion" />
                </p>
                <p className="ms-3">
                  <Link to={'tel:' + phoneLink} className="text-div-link transistion">
                    {phone}
                  </Link>
                </p>
              </div>
              <div className="d-flex">
                <p className="icon-div-footer transistion">
                  <IoLocationOutline className="location-icon" />
                </p>
                <p className="ms-3">
                  <Link to={mapLink} target="_blank" className="text-div-link transistion">
                    {map}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="sub-footer quick-links-div">
            <div className="logo-div">
              <h1>
                <span>Quick Links</span>
              </h1>
            </div>
            <div className="text-div footer-link-div">
              <ul>
                {
                  pages.map((v, i) => {
                    return (
                      <li key={i}>
                        <Link to={v.link} className='footer-links'>
                          <MdDoubleArrow className="footer-list-icons" />
                          {v.name}
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
              <div className="d-flex">

              </div>
            </div>
          </div>
          <div className="sub-footer">
            <div className="logo-div">
              <h1>
                <span>Follow Us On</span>
              </h1>
            </div>
            <div className="text-div">
              <div className="social-media-icon-div">
                <Link to={facebookLink} target="_blank">
                  <div className="footer-icons-div mt-1 me-2 transistion">
                    <FaFacebookF />
                  </div>
                </Link>
                <Link to={instagramLink} target="_blank">
                  <div className="footer-icons-div mt-1 me-2 transistion">
                    <FaInstagram />
                  </div>
                </Link>
                <Link to={twitterLink} target="_blank">
                  <div className="footer-icons-div mt-1 me-2 transistion">
                    <FaTwitter />
                  </div>
                </Link>
                <Link to={linkedInLink} target="_blank">
                  <div className="footer-icons-div mt-1 me-2 transistion">
                    <FaLinkedin />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div >
      <div className="lower-footer">
        <span>Copyright © 2023 Ayetec | All Rights Reserved.</span>
      </div>
    </div >
  );
}