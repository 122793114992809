import './style.css';
import Container from 'react-bootstrap/Container';
import AOS from "aos";
import { useEffect } from 'react';
import IconCheck from "../../assets/AboutUs/icon-check.png"
import { FaRegCheckSquare } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import { TypeAnimation } from 'react-type-animation';
import RouterLink from '../../components/RouterLink/index.jsx';

const AboutUsPage = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className='service-page-img-div about-page-img-div'>
                <Container>
                    <TypeAnimation
                        sequence={[
                            'About Us',
                        ]}
                        wrapper="h2"
                        speed={10}
                        repeat={0}
                        className='service-page-main-hd'
                        cursor={false}
                    />
                    <RouterLink page={'About Us'} />
                </Container>
                <div className='shape-svg-container'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z" fill="#FFFFFF"></path>
                    </svg>
                </div>
            </div>
            <div className="sub-main-about">
                <Container>
                    <div className='service-page-card-div'>
                        <div className='main-text-div-about'>
                            <h2 className='mb-3'>Who we are?</h2>
                            <p>
                                At the core of our identity lies an unwavering commitment to exceed the ordinary.
                                We don't settle for the minimum; instead, we relentlessly pursue perfection. Your
                                satisfaction isn't just a target; it's our guiding principle. Every endeavor is
                                a testament to our steadfast dedication to meeting and surpassing your expectations.
                                We immerse ourselves in the digital realm, embodying the spirit of a born-
                                company. Understanding our clients' unique and evolving needs is embedded in our
                                DNA, and we tirelessly strive to fulfill those needs to the utmost.
                            </p>
                            <p>
                                Digital innovation runs deep in our veins, defining us as a company born in the
                                digital era. We don't just work hard; we live and breathe digital, symbolizing
                                our profound connection to the ever-changing landscape of the online world.
                                Our pride stems from the exceptional quality of our work, and seeing your
                                business thrive is the ultimate validation of our boundless creativity.
                                Every project is an opportunity to elevate our craft, pushing the boundaries
                                of what's possible to unlock new levels of ingenuity and originality.
                            </p>
                            <p>
                                Our commitment to excellence transcends mere words; it's a driving force
                                that ignites our passion for what we do. We take immense pride in our work,
                                viewing each client's success as a reflection of our unwavering dedication to
                                delivering unmatched results. Your business flourishing isn't just a goal;
                                it propels us to new heights of creativity, inspiring us to continually refine
                                and redefine the standards of excellence in our industry. We're not just a
                                service provider; we're your devoted partner in achieving and surpassing your digital aspirations.
                            </p>
                        </div>
                        {/* <div className='d-flex sub-service-page-card-div' >
                            <div className='service-page-card-img-div about-page-card-img-div d-flex'>
                                <div className='img-div-service img-div-about'>
                                    <img src={Img02} className='img-service' alt="img-2" />
                                </div>
                                <div className='img-div-service  img-div-service'>
                                    <img src={Img01} className='img-service' alt="img-1" />
                                </div>
                            </div>
                            <div className='service-page-card-text-div'>
                                <h3
                                    className='mb-3'
                                    data-aos="fade-right"
                                    data-aos-offset="300"
                                    data-aos-duration="700"
                                >About Us</h3>
                                <h1
                                    className='mb-3'
                                    data-aos="fade-left"
                                    data-aos-offset="300"
                                    data-aos-duration="700"
                                >Wo We Are</h1>
                                <p data-aos="zoom-in-up">
                                    We are individuals that do no settle in for the bare minimum.
                                    We strive for perfection, your satisfaction is our primary goal
                                    and we do work hard to attain it. We bleed digital, we are a born
                                    digital company and understand the needs of all of our clients to
                                    the fullest. We take great pride in what we do, and to see your
                                    business flourish takes us to a whole new level of creativeness.
                                </p>
                            </div>
                        </div> */}
                    </div>
                </Container>
            </div>
            <div className="sub-main-about-2">
                <Container className='about-details-container d-flex justify-content-center align-items-center'>
                    <div data-aos-duration="700" data-aos="zoom-in-up" className='main-detail-about main-detail-about-1 transition'>
                        <div className='icon-div-about'>
                            <img src={IconCheck} alt="check_icon" className='about-icon mb-3' />
                            <h2 className='about-hover-color'>How We Operate?</h2>
                        </div>
                        <div className='text-div-about'>
                            <p className='about-hover-color'>
                                Our agile team of skilled designers, developers, programmers, and marketing
                                experts is poised to propel your business forward and guide you to your desired
                                destination.
                            </p>
                        </div>
                    </div>
                    <div data-aos-duration="700" data-aos="zoom-in-up" className='main-detail-about main-detail-about-2'>
                        <div className='icon-div-about-middle '>
                            <h2>Reasons to Choose Us</h2>
                        </div>
                        <div className='text-div-about text-div-about-2'>
                            <p>
                                We are dedicated to comprehensively understanding our clients' needs, translating them into
                                a digital language, and ensuring complete satisfaction. This is our core mission.
                            </p>
                            <p>
                                <FaRegCheckSquare className='check-icon' /> <span className='icon-txt'>24/7 Customer Support</span>
                            </p>
                            <p>
                                <FaRegCheckSquare className='check-icon' /> <span className='icon-txt'>24/7 Awesome Team Members</span>
                            </p>
                            <p>
                                <FaRegCheckSquare className='check-icon' /> <span className='icon-txt'>100% Quality Product</span>
                            </p>
                        </div>
                    </div>
                    <div data-aos-duration="700" data-aos="zoom-in-up" className='main-detail-about main-detail-about-3'>
                        <div className='icon-div-about'>
                            <img src={IconCheck} alt="check_icon" className='about-icon mb-3' />
                            <h2 className='about-hover-color'>Who Do We Support?</h2>
                        </div>
                        <div className='text-div-about'>
                            <p className='about-hover-color'>
                                We implement sustainable and measurable processes to empower your business
                                in navigating today's digital marketplace and maximizing ROI.
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="sub-main-about-3">
                <Container>
                    <div className='appointment-div' data-aos-duration="700" data-aos="zoom-in-up">
                        <div className='appointments-text-div'>
                            <h1>What’s next?</h1>
                            <p>
                                Collaboration begins with a conversation. Whether you’re interested in discussing
                                a new project or simply wish to find out more about our services and what we can
                                do for you, we are here to help. Let’s get the conversation going.
                            </p>
                        </div>
                        <div className='appointments-button-div'>
                            <div>
                                <Button variant="primary" className="contact-submit appointment-button me-4">
                                    Schedule a call with us
                                </Button>
                                <Button variant="primary" className="contact-submit appointment-button">
                                    Start a project
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default AboutUsPage;