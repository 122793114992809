import "./style.css";
import Container from 'react-bootstrap/Container';
import { useState } from "react";
import SvgComponent from "../components/SvgComponent";

//Overlay Images 
import FirstEbook from '../assets/Projects/Ebook/First.png';
import SecondEbook from '../assets/Projects/Ebook/Second.png';
import ThirdEbook from '../assets/Projects/Ebook/Third.png';
import FourthEbook from '../assets/Projects/Ebook/Fourth.png';
import FifthEbook from '../assets/Projects/Ebook/Fifth.png';
import SixthEbook from '../assets/Projects/Ebook/Sixth.png';
import SeventhEbook from '../assets/Projects/Ebook/Seventh.png';
import EightEbook from '../assets/Projects/Ebook/Eight.png';


//Websites Images
import FirstWebsite from '../assets/Projects/Websites/ShahidAndSons.png';
import SecondWebsite from '../assets/Projects/Websites/Smit-devathon-2.png';
import ThirdWebsite from '../assets/Projects/Websites/Smit-devathon-3.png';
import FourthWebsite from '../assets/Projects/Websites/pokemon.png';
import FifthWebsite from '../assets/Projects/Websites/open-sea.png';
import { Link } from "react-router-dom";

//Software
import Hotel from '../assets/Projects/Software/hotel.jpeg';
import RMS from '../assets/Projects/Software/rms.jpeg';
import Trading from '../assets/Projects/Software/trading.jpeg';
import Manufacturing from '../assets/Projects/Software/manufacturing.jpeg';
import HMS from '../assets/Projects/Software/hms.jpeg';
import LIMS from '../assets/Projects/Software/lims.jpeg';

const ProjectSec = () => {
    const [whichProject, setWhichProject] = useState("Websites");

    return (
        <div className="main-service ">
            <h4
                className="procedure-hd"
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-duration="700"
            >
                Case Study
            </h4>
            <div className="main-service-hd-1">
                <h1
                    className="main-service-hd"
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-duration="700"
                >
                    Our Latest Projects
                </h1>
                <SvgComponent />
            </div>
            <Container className="service-container mt-5">
                <div className="projects-btn-div">
                    <button
                        className={`transistion logo-button ${whichProject === 'Websites' ? 'selected-logo-button' : ''}`}
                        onClick={() => setWhichProject('Websites')}
                    >
                        Websites
                    </button>
                    <button
                        className={`transistion logo-button ${whichProject === 'Software' ? 'selected-logo-button' : ''}`}
                        onClick={() => setWhichProject('Software')}
                    >
                        Software Solutions
                    </button>
                    <button
                        className={`transistion logo-button ${whichProject === 'E-Book' ? 'selected-logo-button' : ''}`}
                        onClick={() => setWhichProject('E-Book')}
                    >
                        E-Book
                    </button>
                </div>
                <div className="main-img-container">
                    {
                        whichProject === "Websites" ?
                            <div className="logo-container">
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card website-card">
                                    <Link to={'https://www.shahidnsons.co.uk/'} target="_blank">
                                        <img
                                            className="image"
                                            src={FirstWebsite}
                                            alt="FirstBanner"
                                        />
                                    </Link>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card website-card">
                                    <Link to={'https://smit-event-2.vercel.app/'} target="_blank">
                                        <img
                                            className="image"
                                            src={SecondWebsite}
                                            alt="SecondBanner"
                                        />
                                    </Link>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card website-card">
                                    <Link to={'https://smit-event-eta.vercel.app/'} target="_blank">
                                        <img
                                            className="image"
                                            src={ThirdWebsite}
                                            alt="ThirdBanner"
                                        />
                                    </Link>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card website-card">
                                    <Link to={'https://pokemon-website-1.netlify.app/'} target="_blank">
                                        <img
                                            className="image"
                                            src={FourthWebsite}
                                            alt="FourthBanner"
                                        />
                                    </Link>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card website-card">
                                    <Link to={'https://open-sea-website.netlify.app/'} target="_blank">
                                        <img
                                            className="image"
                                            src={FifthWebsite}
                                            alt="FourthBanner"
                                        />
                                    </Link>
                                </div>
                            </div>
                            : ''}
                    {
                        whichProject === "Software" ?
                            <div className="logo-container">
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={Hotel} alt="Hotel" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">Hotel ERP</h2>
                                        <h3 className="transistion software-hd-2">Property Management System</h3>
                                        <p>
                                            Simplifies daily operations by automating them. Suitable for
                                            all types and sizes of property businesses.
                                        </p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={RMS} alt="RMS" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">RMS</h2>
                                        <h3 className="transistion software-hd-2">Restaurant Management System</h3>
                                        <p>
                                            RMS manages dine-In, delivery, takeaway, orders, menus, deals, waiters,
                                            tables, billings, payments, customer’s ledgers and everything in a smart way.
                                        </p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={RMS} alt="RMS" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">POS Retail</h2>
                                        <h3 className="transistion software-hd-2">Point of Sale Retail</h3>
                                        <p>
                                            Full control, clear visibility and just in time reordering and
                                            effectively increasing focus on the sales function.
                                        </p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={Trading} alt="Trading" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">Trading ERP</h2>
                                        <h3 className="transistion software-hd-2">Trading & Inventory Control System</h3>
                                        <p>
                                            Enhance your success with end-to-end visibility of your integrated process.
                                        </p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={Manufacturing} alt="Manufaturing" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">Manufacturing ERP</h2>
                                        <h3 className="transistion software-hd-2">Manufacturing enterprise resource planning</h3>
                                        <p>
                                            Designed to solve your daily challenges, increase efficiency and
                                            enhance productivity.
                                        </p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={HMS} alt="HMS" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">HMS</h2>
                                        <h3 className="transistion software-hd-2">Hospital Management System</h3>
                                        <p>
                                            Simplifies daily operations by automating them. Suitable for
                                            all types and sizes of property HMS is designed for midsized to large
                                            sized hospitals and clinics, which covers the wide range of hospital
                                            administration and patient management processes
                                            .</p>
                                    </div>
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="software-card">
                                    <div className="software-img-div">
                                        <img src={LIMS} alt="LIMS" />
                                    </div>
                                    <div className="transistion software-text-div">
                                        <h2 className="transistion software-hd-1">LIMS</h2>
                                        <h3 className="transistion software-hd-2">Laboratory Information Management System</h3>
                                        <p>
                                            LIMS are designed for a wide array of laboratory environments, operating
                                            in various disciplines and industries.
                                        </p>
                                    </div>
                                </div>
                                {/* <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={FirstLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={SecondLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={ThirdLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={FourthLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={FifthLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={SixthLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={SeventhLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={EightLogo} muted type="video/mp4" />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card">
                                    <video controls={false} className="image" autoPlay loop src={NinthLogo} muted type="video/mp4" />
                                </div> */}
                            </div>
                            : ''}

                    {
                        whichProject === "E-Book" ?
                            <div className="logo-container">
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={FirstEbook}
                                        alt="FirstEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={SecondEbook}
                                        alt="SecondEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={ThirdEbook}
                                        alt="ThirdEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={FourthEbook}
                                        alt="FourthEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={FifthEbook}
                                        alt="FifthEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={SixthEbook}
                                        alt="SixthEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={SeventhEbook}
                                        alt="SeventhEbook"
                                    />
                                </div>
                                <div data-aos-duration="700" data-aos="zoom-in-up" className="logo-card banner-card">
                                    <img
                                        className="image"
                                        src={EightEbook}
                                        alt="EightEbook"
                                    />
                                </div>
                            </div>
                            : ''}
                </div>
            </Container>
        </div>
    );
};

export default ProjectSec;