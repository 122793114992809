import './style.css';
import Container from 'react-bootstrap/Container';
import AOS from "aos";
import { useEffect } from 'react';
import CounterSec from "../../sections/Counter.jsx";
import ServiceSec from "../../sections/Services.jsx";
import card1 from "../../assets/Service/img-service-02.jpg";
import card2 from "../../assets/Service/img-service-03.jpg";
import card3 from "../../assets/Service/UI-UX-intro-img.jpg";
import { TypeAnimation } from 'react-type-animation';
import RouterLink from '../../components/RouterLink/index.jsx';


const ServicePage = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div>
                <div className='service-page-img-div'>
                    <Container>
                        <TypeAnimation
                            sequence={[
                                'Services',
                            ]}
                            wrapper="h1"
                            speed={10}
                            repeat={0}
                            className='service-page-main-hd'
                            cursor={false}
                        />
                        <RouterLink page={'Service'} />
                    </Container>
                    <div className='shape-svg-container'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                            <path className="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z" fill="#FFFFFF"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <ServiceSec />
            <Container>
                <div className='service-page-card-div'>
                    <div className='d-flex sub-service-page-card-div' data-aos-duration="700" data-aos="zoom-in-up" >
                        <div className='service-page-card-img-div'>
                            <img src={card2} alt="card-img" className='service-page-card-img' />
                        </div>
                        <div className='service-page-card-text-div'>
                            <h1 className='mb-3'>
                                We Offer <span>Data</span>
                                <br />
                                Backup <span>Services</span>
                            </h1>
                            <h2>Business analysis process mapping through architecture desegn and data.</h2>
                            <p data-aos="zoom-in-up">
                                Our team of professionals utilizes cutting-edge technologies and adheres to industry best
                                practices to effectively manage your data, including backup, recovery, and secure
                                destruction. We are adept at resolving any data conflicts and offer a unique maintenance
                                solution to ensure optimal data management.
                            </p>
                        </div>
                    </div>
                    <div className='d-flex sub-service-page-card-div' data-aos-duration="700" data-aos="zoom-in-up" >
                        <div className='service-page-card-text-div service-page-card-text-div-middle mt-100px'>
                            <h1 className='mb-3'>
                                Data <span>Management</span> &
                                <br />
                                <span>Maintenance</span>
                            </h1>
                            <p data-aos="zoom-in-up">
                                Our seasoned maintenance and support team is here to assist you with technical issues
                                related to big data services, ensuring smooth and reliable operation of your entire
                                system. At Ayetec, we offer a comprehensive range of managed and support services for
                                big data, including backup solutions, 24/7 support, archival support, administrative
                                tasks, configuration management, policy enforcement, security measures, and proactive
                                maintenance.
                            </p>
                        </div>
                        <div className='service-page-card-img-div'>
                            <img src={card1} alt="card-img" className='service-page-card-img' />
                        </div>
                    </div>
                    <div className='d-flex sub-service-page-card-div' data-aos-duration="700" data-aos="zoom-in-up" >
                        <div className='service-page-card-img-div d-flex'>
                            <img src={card3} alt="card-img" className='service-page-card-img' />
                        </div>
                        <div className='service-page-card-text-div'>
                            <h1 className='mb-3'>
                                Idea
                                <br />
                                <span>Model</span>
                            </h1>
                            <p data-aos="zoom-in-up">
                                Whether you're seeking to validate a design before moving forward, advance your
                                product into development, need a functional prototype for crowdfunding, or are
                                prepared to present your innovation to potential investors, prototyping an
                                invention idea can yield significant benefits for your project. It enables
                                you to iterate on your concept, refine your ideas, and gain a competitive
                                advantage in the process.
                            </p>
                        </div>
                    </div>


                    <div className="counter-div-service-page">
                        <CounterSec />
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ServicePage;