import './style.css';
import Container from 'react-bootstrap/Container';
import AOS from "aos";
import { useEffect } from 'react';
import CounterSec from '../../sections/Counter.jsx'
import JobPageBanner from '../../assets/Career/jobpagebannerimg.png';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

const CareerPage = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <div className="job-page-container">
                <Container>
                    <div className='hero-section-div'>
                        <div className='hero-text-div'>
                            <div>
                                <h1>
                                    Find Your
                                    <br />
                                    <span>Dream</span> Job
                                </h1>
                                <p>
                                    Finding your dream job is a journey that intertwines passion,
                                    purpose, and professional fulfillment. It goes beyond the
                                    conventional definition of work; it's about discovering a
                                    vocation that aligns with your values, interests, and skills.
                                </p>
                            </div>
                        </div>
                        <div className='hero-img-div'>
                            <img src={JobPageBanner} alt="JobPageBanner" />
                        </div>
                    </div>
                    <div className='jobs-main-div'>
                        <div className='job-card-div'>
                            <div className='job-num-div'>
                                1
                            </div>
                            <h3 className='job-hd'>
                                <span className='sub-job-hd'>Ui/Ux</span>
                            </h3>
                            <h3 className='job-hd'>
                                Designer
                            </h3>
                            <p className='job-dis'>department: production</p>
                            <p className='job-dis'>Job type: on-site</p>
                            <p className='job-dis'>location: karachi</p>
                            <Link to={'/ContactUs'} className="contact-now-link">
                                <Button variant="primary" className="contact-submit transistion contact-now-button" type="submit">
                                    Apply Now
                                </Button>
                            </Link>
                        </div>
                        <div className='job-card-div'>
                            <div className='job-num-div'>
                                2
                            </div>
                            <h3 className='job-hd'>
                                <span className='sub-job-hd'>Web</span>
                            </h3>
                            <h3 className='job-hd'>
                                Developer
                            </h3>
                            <p className='job-dis'>department: production</p>
                            <p className='job-dis'>Job type: on-site</p>
                            <p className='job-dis'>location: karachi</p>
                            <Link to={'/ContactUs'} className="contact-now-link">
                                <Button variant="primary" className="contact-submit transistion contact-now-button" type="submit">
                                    Apply Now
                                </Button>
                            </Link>
                        </div>
                        <div className='job-card-div'>
                            <div className='job-num-div'>
                                3
                            </div>
                            <h3 className='job-hd'>
                                <span className='sub-job-hd'>S.E.O</span>
                            </h3>
                            <h3 className='job-hd'>
                                Specialist
                            </h3>
                            <p className='job-dis'>department: production</p>
                            <p className='job-dis'>Job type: on-site</p>
                            <p className='job-dis'>location: karachi</p>
                            <Link to={'/ContactUs'} className="contact-now-link">
                                <Button variant="primary" className="contact-submit transistion contact-now-button" type="submit">
                                    Apply Now
                                </Button>
                            </Link>
                        </div>
                        <div className='job-card-div'>
                            <div className='job-num-div'>
                                4
                            </div>
                            <h3 className='job-hd'>
                                <span className='sub-job-hd'>Ebook</span>
                            </h3>
                            <h3 className='job-hd'>
                                Sales
                            </h3>
                            <p className='job-dis'>department: production</p>
                            <p className='job-dis'>Job type: on-site</p>
                            <p className='job-dis'>location: karachi</p>
                            <Link to={'/ContactUs'} className="contact-now-link">
                                <Button variant="primary" className="contact-submit transistion contact-now-button" type="submit">
                                    Apply Now
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Container>
                <CounterSec/>
            </div >
        </>
    )
}
export default CareerPage;